import React from 'react';
import styled from 'styled-components';
import { Card, Image, Flex, Box, Text, Link } from 'rebass';
import banner from '../../assets/banner.jpg';
import cover from '../../assets/Platnica.jpg';

const List = styled.ul`
  list-style-type: none;
  li {
    margin-bottom: 0.2rem;
    span {
      &:first-child {
        display: inline-block;
        width: 30px;
        text-align: center;
      }
    }
  }
`;

function CoursesHeader() {
  return (
    <header>
      {/* <Box
        sx={{
          px: 4,
          py: [5, 5, 4, 4],
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          borderRadius: 5,
          color: 'white',
          bg: 'gray',
        }}
      >
      </Box> */}
      <Card width="100%" pt={3}>
        <Image src={banner} />
      </Card>

      <h3>Kaj je to?</h3>
      <Flex mx={-2} flexWrap="wrap">
        <Box width={1 / 2} px={1} minWidth="350px">
          <p>
            »Raziskujmo krščanstvo« sestavlja serija avdiovizualnih lekcij na
            spletu in DVD-ju, ki tvorijo seminar namenjen vsem, ki želijo
            raziskovati krščanstvo v manj formalnem okolju. Posamezne lekcije
            seminarja vodijo udeležence skozi Markov evangelij, razkrivajo kdo
            je bil Jezus, kakšen je bil njegov namen in kaj pomeni slediti
            Jezusu.
          </p>

          <p>
            Tiskan učbenik je na voljo za 2€.{' '}
            <Link
              sx={{ color: '#003f7d' }}
              href="mailto:mel.patty.davis@gmail.com"
            >
              Kontaktirate nas, če bi jih želeli naročiti.
            </Link>
          </p>
        </Box>
        <Box width={1 / 2} px={1}>
          <Flex>
            <Card
              width={[175, 225]}
              mx="auto"
              sx={{
                boxShadow: '0 0 4px rgba(0, 0, 0, .125)',
                p: 2,
              }}
            >
              <Image src={cover} alt="Platnica učbenika" />
              <Text>Učbenik na voljo</Text>
            </Card>
          </Flex>
        </Box>
      </Flex>
      {/* <Image
        // width={[1, 1, 1 / 2]}
        height={350}
        pt={[2]}
        pl={[2, 2, 3]}
        src={cover}
        alt="Platnica učbenika"
      /> */}
    </header>
  );
}

export default CoursesHeader;

// eslint-disable-next-line no-lone-blocks
{
  /*
<li>
  <span role="img" aria-label="memo">
    📝
  </span>{' '}
  Use .mdx file to save course & lesson information and notes. This is a
  test
</li>
<li>
  <span role="img" aria-label="video Camera">
    📹
  </span>{' '}
  Youtube as video host
</li>
<li>
  <span role="img" aria-label="motorcycle">
    🏍️
  </span>{' '}
  Support autoplay
</li>
<li>
  <span role="img" aria-label="chart increasing">
    💹
  </span>{' '}
  Save learning progress
</li>
<li>
  <span role="img" aria-label="heart">
    💖
  </span>{' '}
  Bookmark courses
</li> */
}
